import React from "react"

import { useApp } from "../../hooks/useApp"
import { useArticles } from "../../hooks/useArticles"
import { useSection } from "../../hooks/useSection"
import { useSanity } from "../../hooks/useSanity"

export const withArticles = Component => ({ name = `Articles`, page }) => {
  const { availableCategories: categories, filteredArticles: articles, handleResetFilters, filters, handleFilters } = useArticles()
  const { transformSection } = useSection()
  const { heroNormaliser, richTextNormaliser, textNormaliser } = useSanity()
  const { setInvertedTheme, setTransparentTheme } = useApp()

  const content = {
    ...page,
    title: textNormaliser(page._rawTitle),
    titleText: richTextNormaliser(page._rawTitle),
    heroBanner: heroNormaliser(page?.heroBanner),
  }
  const hero = transformSection(content.hero)

  if (content?.settings) {
    setInvertedTheme(content.settings.dark)
    setTransparentTheme(content.settings.transparent)
  }

  Component.displayName = name
  return (
    <Component
      articles={articles}
      categories={categories}
      content={content}
      filters={filters}
      handleFilters={handleFilters}
      handleResetFilters={handleResetFilters}
      hero={hero}
    />
  )
}
