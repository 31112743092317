import React from "react"
import { graphql } from "gatsby"

import { Articles as Page } from "../components/Articles/Articles"

export const query = graphql`
  query {
    page: sanityStoriesPage {
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      _rawTitle(resolveReferences: { maxDepth: 10 })
      heroBanner: _rawHeroBanner(resolveReferences: { maxDepth: 10 })
      settings {
        dark
        transparent
      }
      description
    }
  }
`

export default ({ data, ...props }) => <Page {...props} {...data} />
